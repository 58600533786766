import request from "@/utils/request"
import downloadRequest from "@/utils/downloadRequest";

/**
 * 首页获取全部概览信息
 */
export const getStatistics = () => {
  return request({
    method: 'GET',
    url: '/workOrder/statistics/order'
  });
}

/**
 *  获取order列表
 * @param {*} params  {"workStatusList":}
 * @returns {Array} order列表
 */
export const getOrderList = (params = {}) => {
  return request({
    method: 'GET',
    url: "/workOrder/list",
    params
  });
}

/**
 * 获取order详情
 * @param {*} params 
 * @returns 
 */
export const getDetails = (params) => {
  return request({
    method: "GET",
    url: "/workOrder/details",
    params
  });
}

/**
 * 变更order状态
 * @param {*} params 
 * @returns 
 */
export const exWorkOrder = (params) => {
  return request({
    method: "POST",
    url: "/workOrder/exWorkOrder",
    data: params
  });
}

/**
 * 匹配设备与工单
 */
export const CheckMachine = (params) => {
  return new Promise((reslove) => {
    if (params.machineCode == "123456") {
      reslove({
        code: 200,
        data: {
          inspectionUnit: "国网浙江省电力有限公司建德市供电公司",
          machineName: "手持式变压器变比测试仪",
          factoryNo: "1030",
          manufacturer: "保定市力兴电子设备有限公司",
          sampleNumber: "23063783",
          certificateNo: "变-0012-2023",
          specification: "LX5265",
          manufactureDate: "2019/11/25",
          accuracyClass_maximumTolerance: "",
          controlledNo: "ZDS/05/04/618-01",
        }
      });
    } else {
      reslove({
        code: 404,
        data: {}
      });
    }
  });
}

/**
 * 获取填充数据
 */
export const getOrderValue = (params) => {
  return request({
    method: "GET",
    url: "/SimpleFinalExcel/send",
    params
  });
}
/**
 * 
 * @param {*} params  {orderId:Number } //工单ID
 * @returns PDF文件
 */
export const getPdfFile = (params) => {
  console.log(params);
  return downloadRequest({
    url: "/SimpleFinalExcel/OrderPDF",
    method: "GET",
    params
  });
}

/**
 * 
 */
export const rejectOrder = function (params) {
  return request({
    url: "SimpleFinalExcel/OrderReject",
    method: "POST",
    data: params
  });
}

/**
 * 
 */
export const viewOldData = function (params) {
  return request({
    url: "/SimpleFinalExcel/simpleRecordExcel",
    method: "get",
    params
  });
}