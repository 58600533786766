<template>
  <div class="index-view-box">
    <div class="order-info-box">
      <div class="card order-card" @click="jump('orderManagement/waitSampleOrder')">
        <div class="number">{{ statistics.unsampled }}</div>
        <div>检测人待领样工单</div>
      </div>
      <div class="card order-card" @click="jump('orderManagement/peddingOrder')">
        <div class="number">{{ statistics.detecting || 0 }}</div>
        <div>检测中工单</div>
      </div>
      <div class="card order-card" @click="jump('orderManagement/completedOrder')">
        <div class="number">{{ statistics.sampled || 0 }}</div>
        <div>已完成拟稿</div>
      </div>
    </div>
    <div class="order-table-box">
      <view-card title="待领样工单">
        <div slot="header-left">
          <span class="more" @click="jump('orderManagement/waitSampleOrder')">{{ "查看全部>" }}</span>
        </div>
        <Table :option="tableConfig" :data="tableData" slot="content"></Table>
      </view-card>
    </div>
  </div>
</template>

<script>
import Table from "@/components/Table";
import tableConfig from "./tableConfig";
import { getOrderList, getStatistics } from "@/api/order";

//table配置信息
export default {
  name: "index_view",
  components: {
    Table,
  },
  data() {
    return {
      tableOptin: {},
      tableData: [],
      statistics: {
        detecting: 0,
        sampled: 0,
        unsampled: 0,
      },
    };
  },
  methods: {
    //路有跳转
    jump(url) {
      this.$router.push({ path: url });
    },
    getStatistics() {
      getStatistics()
        .then((res) => {
          this.statistics = res.data;
        })
        .catch((e) => {
          console.error(e);
        });
    },
    getOrderList() {
      getOrderList({ workStatusList: "0", pageSize: 10, pageNum: 1 })
        .then((res) => {
          this.tableData = res.rows;
        })
        .catch((e) => {
          console.error(e);
        });
    },
  },
  created() {
    this.tableConfig = tableConfig(this);
    this.getStatistics();
    this.getOrderList();
  },
};
</script>

<style lang="less" scoped>
.index-view-box {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;

  .order-info-box {
    width: 100%;
    height: 13%;
    min-height: 160px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    gap: 20px;
  }

  .card {
    height: 100%;
    width: 33%;
    border-radius: 10px;
    background-color: #ffffff;
  }

  .order-card {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    color: #101010;
    font-size: 20px;
    font-weight: 500;
    position: relative;
    cursor: pointer;

    &:hover {
      background-color: #f5f6fa;
    }

    .number {
      font-size: 48px;
      color: var(--blue);
    }
  }

  .order-table-box {
    background-color: #ffffff;
    border-radius: 10px;
    flex: 1;
    overflow: hidden;

    .more {
      cursor: pointer;
      color: var(--blue);
      letter-spacing: 4px;
      font-size: 16px;
      font-weight: 600;

      &:hover {
        color: var(--blue-hover);
      }
    }
  }
}
</style>
